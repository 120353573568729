import { Navigate } from "react-router"
import { useAppSelector } from "../redux/hooks"

const CustomRouter = () => {

    const game = useAppSelector(state => state.lobby.game)

    if (game && !game.finished) {
        return <Navigate to={`/${game.id}`}/>
    }

    return <></>
}

export default CustomRouter