import styles from "./Testcard.module.css"

const Dummy = () => {

    return (
        <div className={styles.dummy}>
        </div>
    )
}

export default Dummy