import { allRules } from "../../carddata/scorerules"
import { CardColor, ScoreId, ScoreType } from "../../carddata/types"
import styles from "./Testcard.module.css"

interface MarkerI {
    color: CardColor | undefined
}

const Marker = ({ color }: MarkerI) => {

    const cssClasses = [styles.marker]
    if (color === CardColor.GREEN) cssClasses.push(styles.green)
    else if (color === CardColor.RED) cssClasses.push(styles.red)
    else if (color === CardColor.ORANGE) cssClasses.push(styles.orange)
    else if (color === CardColor.YELLOW) cssClasses.push(styles.yellow)
    else if (color === CardColor.PINK) cssClasses.push(styles.pink)
    else if (color === CardColor.PURPLE) cssClasses.push(styles.purple)

    return (
        <div className={cssClasses.join(" ")}></div>
    )
}

interface RuleI {
    id: number
}

const Rule = ({ id }: RuleI) => {

    const ruleData = allRules.find(r => r.id === id)

    if (!ruleData) return <></>

    let ruleText = [
        <span className={styles.ruleText}>{ruleData.points}</span>,
        <span className={styles.ruleText}>/</span>
    ]

    if (ruleData.type === ScoreType.BASIC && ruleData.amount) {
        for (var i = 0; i < ruleData.amount; i++) ruleText.push(<Marker color={ruleData.color} />)
    } else if (ruleData.type === ScoreType.COMBI && ruleData.colors) {
        ruleData.colors.forEach(c => {
            ruleText.push(<Marker color={c} />)
        })
    } else if (ruleData.type === ScoreType.MAX) {
        ruleText.push(<span className={styles.ruleText}>most</span>)
        if (ruleData.color) ruleText.push(<Marker color={ruleData.color} />)
        else ruleText.push(<span className={styles.ruleText}>total</span>)
    } else if (ruleData.type === ScoreType.MIN) {
        ruleText.push(<span className={styles.ruleText}>fewest</span>)
        if (ruleData.color) ruleText.push(<Marker color={ruleData.color} />)
        else ruleText.push(<span className={styles.ruleText}>total</span>)
    } else if (ruleData.type === ScoreType.EVEN && ruleData.color) {
        ruleText.push(<span className={styles.ruleText}>even</span>)
        ruleText.push(<Marker color={ruleData.color} />)
    } else if (ruleData.type === ScoreType.ODD && ruleData.color) {
        ruleText.push(<span className={styles.ruleText}>odd</span>)
        ruleText.push(<Marker color={ruleData.color} />)
    } else if (ruleData.type === ScoreType.MULTIPLE) {
        if (id === ScoreId.ALL_0) ruleText.push(<span className={styles.ruleText}>missing color</span>)
        else if (id === ScoreId.ALL_2) ruleText.push(<span className={styles.ruleText}>color with 2+</span>)
        else if (id === ScoreId.ALL_3) ruleText.push(<span className={styles.ruleText}>color with 3+</span>)
    }

    return (
        <div className={styles.rule}>
            {ruleText}
        </div>
    )
}

export default Rule