import { useParams } from "react-router"
import { useAppSelector } from "../redux/hooks"
import GamePage from "./GamePage"
import LobbyPage from "./LobbyPage"
import { useEffect } from "react"
import { joinGame } from "../util/websocket"
import styles from "./GamePage.module.css"
import MainPage from "./MainPage"

const GameRedirect = () => {

    const { gameId } = useParams()
    const playerId = useAppSelector(state => state.lobby.playerId)

    useEffect(() => {
        if (gameId && playerId) joinGame(gameId, playerId)
    }, [gameId, playerId])

    const game = useAppSelector(state => state.lobby.game)

    if (game) {
        if (!game.started) return <LobbyPage />
        else return <GamePage />
    }
    else return <div className={styles.loading}>Loading...</div>
}

export default GameRedirect