import { CardColor, CardData, ScoreId } from "./types"

export const redCards: CardData[] = [
    { id: 1, color: CardColor.RED, scoreRules: [ScoreId.PINK_2] },
    { id: 2, color: CardColor.RED, scoreRules: [ScoreId.PINK_1, ScoreId.ORANGE_1] },
    { id: 3, color: CardColor.RED, scoreRules: [ScoreId.PINK_5] },
    { id: 4, color: CardColor.RED, scoreRules: [ScoreId.PINK_2, ScoreId.YELLOW_2, ScoreId.PURPLE_m4] },
    { id: 5, color: CardColor.RED, scoreRules: [ScoreId.PINK_3, ScoreId.PURPLE_m1, ScoreId.RED_m1] },
    { id: 6, color: CardColor.RED, scoreRules: [ScoreId.ORANGE_GREEN] },
    { id: 7, color: CardColor.RED, scoreRules: [ScoreId.PINK_3, ScoreId.YELLOW_m2] },
    { id: 8, color: CardColor.RED, scoreRules: [ScoreId.PINK_EVEN, ScoreId.PINK_ODD] },
    { id: 9, color: CardColor.RED, scoreRules: [ScoreId.PINK_MIN] },
    { id: 10, color: CardColor.RED, scoreRules: [ScoreId.PINK_1, ScoreId.PURPLE_1] },
    { id: 11, color: CardColor.RED, scoreRules: [ScoreId.PURPLE_YELLOW] },
    { id: 12, color: CardColor.RED, scoreRules: [ScoreId.RED_PINK_GREEN] },
    { id: 13, color: CardColor.RED, scoreRules: [ScoreId.ALL_COLORS] },
    { id: 14, color: CardColor.RED, scoreRules: [ScoreId.PINK_8] },
    { id: 15, color: CardColor.RED, scoreRules: [ScoreId.PINK_MAX] },
    { id: 16, color: CardColor.RED, scoreRules: [ScoreId.PINK_4, ScoreId.ORANGE_m2, ScoreId.GREEN_m2] },
    { id: 17, color: CardColor.RED, scoreRules: [ScoreId.ORANGE_PINK_YELLOW] },
    { id: 18, color: CardColor.RED, scoreRules: [ScoreId.PINK_2, ScoreId.PURPLE_1, ScoreId.RED_m2] }
]

export const greenCards: CardData[] = [
    { id: 19, color: CardColor.GREEN, scoreRules: [ScoreId.ORANGE_RED]},
    { id: 20, color: CardColor.GREEN, scoreRules: [ScoreId.PURPLE_PINK_YELLOW]},
    { id: 21, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_MAX]},
    { id: 22, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_MIN]},
    { id: 23, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_EVEN, ScoreId.YELLOW_ODD]},
    { id: 24, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_2, ScoreId.RED_1, ScoreId.GREEN_m2]},
    { id: 25, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_5]},
    { id: 26, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_8]},
    { id: 27, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_3, ScoreId.PURPLE_m2]},
    { id: 28, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_1, ScoreId.RED_1]},
    { id: 29, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_4, ScoreId.PINK_m2, ScoreId.ORANGE_m2]},
    { id: 30, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_1, ScoreId.PINK_1]},
    { id: 31, color: CardColor.GREEN, scoreRules: [ScoreId.PURPLE_PINK]},
    { id: 32, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_3, ScoreId.RED_m1, ScoreId.GREEN_m1]},
    { id: 33, color: CardColor.GREEN, scoreRules: [ScoreId.GREEN_YELLOW_ORANGE]},
    { id: 34, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_2]},
    { id: 35, color: CardColor.GREEN, scoreRules: [ScoreId.YELLOW_2, ScoreId.PURPLE_2, ScoreId.RED_m4]},
    { id: 36, color: CardColor.GREEN, scoreRules: [ScoreId.ALL_MIN]}
]

export const yellowCards: CardData[] = [
    { id: 37, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_2]},
    { id: 38, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_5]},
    { id: 39, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_8]},
    { id: 40, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_MAX]},
    { id: 41, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_MIN]},
    { id: 42, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_EVEN, ScoreId.GREEN_ODD]},
    { id: 43, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_1, ScoreId.PINK_1]},
    { id: 44, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_2, ScoreId.ORANGE_2, ScoreId.PINK_m4]},
    { id: 45, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_3, ScoreId.PINK_m1, ScoreId.YELLOW_m1]},
    { id: 46, color: CardColor.YELLOW, scoreRules: [ScoreId.PURPLE_RED]},
    { id: 47, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_2, ScoreId.PINK_1, ScoreId.YELLOW_m2]},
    { id: 48, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_YELLOW_PURPLE]},
    { id: 49, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_4, ScoreId.RED_m2, ScoreId.PURPLE_m2]},
    { id: 50, color: CardColor.YELLOW, scoreRules: [ScoreId.ORANGE_PINK]},
    { id: 51, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_1, ScoreId.RED_1]},
    { id: 52, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_3, ScoreId.ORANGE_m2]},
    { id: 53, color: CardColor.YELLOW, scoreRules: [ScoreId.GREEN_RED_ORANGE]},
    { id: 54, color: CardColor.YELLOW, scoreRules: [ScoreId.ALL_MAX]}
]

export const orangeCards: CardData[] = [
    { id: 55, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_2]},
    { id: 56, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_5]},
    { id: 57, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_8]},
    { id: 58, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_MAX]},
    { id: 59, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_MIN]},
    { id: 60, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_EVEN, ScoreId.PURPLE_ODD]},
    { id: 61, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_1, ScoreId.GREEN_1]},
    { id: 62, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_2, ScoreId.GREEN_1, ScoreId.ORANGE_m2]},
    { id: 63, color: CardColor.ORANGE, scoreRules: [ScoreId.RED_GREEN]},
    { id: 64, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_2, ScoreId.RED_2, ScoreId.GREEN_m4]},
    { id: 65, color: CardColor.ORANGE, scoreRules: [ScoreId.ORANGE_PURPLE_PINK]},
    { id: 66, color: CardColor.ORANGE, scoreRules: [ScoreId.YELLOW_PURPLE_RED]},
    { id: 67, color: CardColor.ORANGE, scoreRules: [ScoreId.ALL_3]},
    { id: 68, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_1, ScoreId.YELLOW_1]},
    { id: 69, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_3, ScoreId.RED_m2]},
    { id: 70, color: CardColor.ORANGE, scoreRules: [ScoreId.PINK_YELLOW]},
    { id: 71, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_4, ScoreId.YELLOW_m2, ScoreId.PINK_m2]},
    { id: 72, color: CardColor.ORANGE, scoreRules: [ScoreId.PURPLE_3, ScoreId.GREEN_m1, ScoreId.ORANGE_m1]}
]

export const purpleCards: CardData[] = [
    { id: 73, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_2]},
    { id: 74, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_5]},
    { id: 75, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_8]},
    { id: 76, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_EVEN, ScoreId.ORANGE_ODD]},
    { id: 77, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_MAX]},
    { id: 78, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_MIN]},
    { id: 79, color: CardColor.PURPLE, scoreRules: [ScoreId.RED_YELLOW]},
    { id: 80, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_2, ScoreId.PINK_2, ScoreId.YELLOW_m4]},
    { id: 81, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_2, ScoreId.YELLOW_1, ScoreId.PURPLE_m2]},
    { id: 82, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_4, ScoreId.GREEN_m2, ScoreId.RED_m2]},
    { id: 83, color: CardColor.PURPLE, scoreRules: [ScoreId.ALL_0]},
    { id: 84, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_1, ScoreId.YELLOW_1]},
    { id: 85, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_1, ScoreId.GREEN_1]},
    { id: 86, color: CardColor.PURPLE, scoreRules: [ScoreId.GREEN_ORANGE_PINK]},
    { id: 87, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_3, ScoreId.YELLOW_m1, ScoreId.PURPLE_m1]},
    { id: 88, color: CardColor.PURPLE, scoreRules: [ScoreId.ORANGE_3, ScoreId.PINK_m2]},
    { id: 89, color: CardColor.PURPLE, scoreRules: [ScoreId.GREEN_PINK]},
    { id: 90, color: CardColor.PURPLE, scoreRules: [ScoreId.PURPLE_ORANGE_RED]}
]

export const pinkCards: CardData[] = [
    { id: 91, color: CardColor.PINK, scoreRules: [ScoreId.RED_2]},
    { id: 92, color: CardColor.PINK, scoreRules: [ScoreId.RED_5]},
    { id: 93, color: CardColor.PINK, scoreRules: [ScoreId.RED_8]},
    { id: 94, color: CardColor.PINK, scoreRules: [ScoreId.RED_MAX]},
    { id: 95, color: CardColor.PINK, scoreRules: [ScoreId.RED_MIN]},
    { id: 96, color: CardColor.PINK, scoreRules: [ScoreId.RED_EVEN, ScoreId.RED_ODD]},
    { id: 97, color: CardColor.PINK, scoreRules: [ScoreId.RED_1, ScoreId.ORANGE_1]},
    { id: 98, color: CardColor.PINK, scoreRules: [ScoreId.PINK_YELLOW_RED]},
    { id: 99, color: CardColor.PINK, scoreRules: [ScoreId.RED_4, ScoreId.PURPLE_m2, ScoreId.YELLOW_m2]},
    { id: 100, color: CardColor.PINK, scoreRules: [ScoreId.RED_2, ScoreId.ORANGE_1, ScoreId.PINK_m2]},
    { id: 101, color: CardColor.PINK, scoreRules: [ScoreId.PURPLE_GREEN_RED]},
    { id: 102, color: CardColor.PINK, scoreRules: [ScoreId.ALL_2]},
    { id: 103, color: CardColor.PINK, scoreRules: [ScoreId.PURPLE_GREEN]},
    { id: 104, color: CardColor.PINK, scoreRules: [ScoreId.RED_2, ScoreId.GREEN_2, ScoreId.ORANGE_m4]},
    { id: 105, color: CardColor.PINK, scoreRules: [ScoreId.RED_3, ScoreId.ORANGE_m1, ScoreId.PINK_m1]},
    { id: 106, color: CardColor.PINK, scoreRules: [ScoreId.RED_1, ScoreId.PURPLE_1]},
    { id: 107, color: CardColor.PINK, scoreRules: [ScoreId.RED_3, ScoreId.GREEN_m2]},
    { id: 108, color: CardColor.PINK, scoreRules: [ScoreId.ORANGE_YELLOW]}
]

export const allcards: CardData[] = redCards.concat(yellowCards, pinkCards, orangeCards, purpleCards, greenCards)