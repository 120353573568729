import { allcards } from "../../carddata/allcards"
import { CardColor } from "../../carddata/types"
import { useAppSelector } from "../../redux/hooks"
import { Card, CardState } from "../../util/types"
import Testcard from "../testcard/Testcard"
import styles from "./CardDisplay.module.css"
import colorStyles from "../testcard/Testcard.module.css"
import { endTurn } from "../../util/websocket"

interface ColorCounterI {
    color: CardColor
    count: number
}


const ColorCounter = ({ color, count }: ColorCounterI) => {

    const cssStyles = [styles.counter]

    if (color === CardColor.RED) cssStyles.push(colorStyles.red)
    else if (color === CardColor.GREEN) cssStyles.push(colorStyles.green)
    else if (color === CardColor.ORANGE) cssStyles.push(colorStyles.orange)
    else if (color === CardColor.YELLOW) cssStyles.push(colorStyles.yellow)
    else if (color === CardColor.PINK) cssStyles.push(colorStyles.pink)
    else if (color === CardColor.PURPLE) cssStyles.push(colorStyles.purple)

    return (
        <div className={cssStyles.join(" ")}>{count}</div>
    )
}

interface CardDisplayI {
    id: string,
    cards: Card[]
}


const CardDisplay = ({ id, cards }: CardDisplayI) => {

    const player = useAppSelector(state => state.lobby.players.find(p => p.id === id))
    const gamePlayer = useAppSelector(state => state.lobby.game?.players.find(p => p.id === id))
    const playerId = useAppSelector(state => state.lobby.playerId)
    const game = useAppSelector(state => state.lobby.game)
    const players = useAppSelector(state => state.lobby.players)
    if (!player || !gamePlayer || !playerId || !game || !players) return <></>

    const isPlayer = playerId === id

    const pointCards = cards.filter(c => c.state === CardState.POINTS).map(c => {
        return <Testcard id={c.id} state={c.state} onBoard={false} ofPlayer={isPlayer} />
    })

    const cardColors = [CardColor.RED, CardColor.GREEN, CardColor.ORANGE, CardColor.PINK, CardColor.YELLOW, CardColor.PURPLE]
    const playerColors = cards.filter(c => c.state === CardState.COLOR).map(c => {
        const cardData = allcards.find(cd => cd.id === c.id)
        if (cardData) return cardData.color
        else return undefined
    })

    const counters = cardColors.map(c => {
        return {
            color: c,
            amount: playerColors.filter(pc => pc === c).length
        }
    }).filter(d => d.amount > 0).map(d => <ColorCounter color={d.color} count={d.amount} />)

    const displayStyles = [styles.carddisplay]
    if ((!game.finished && isPlayer && game.currentPlayerId === id && game.flipPhase) ||
       (!game.finished && !isPlayer && game.currentPlayerId === id) ) {
        displayStyles.push(styles.highlight)
    } else if (game.finished) {
        const sortedIds = game.players.slice().sort((a, b) => b.points - a.points).map(p => p.id)
        const winner = sortedIds.length > 0 ? players.find(p => p.id === sortedIds[0]) : undefined
        if (winner && winner.id === id) displayStyles.push(styles.highlightRed)
    }

    const onEndTurn = () => {
        endTurn(game.id)
    }

    return (
        <div className={displayStyles.join(" ")}>
            <div className={styles.info}>
                <div className={styles.playername}>{player.name}</div>
                <div>{gamePlayer.points}</div>
                <button hidden={game.finished || game.currentPlayerId !== playerId || !game.flipPhase || game.currentPlayerId !== id} onClick={onEndTurn} className={styles.endButton}>End Turn</button>
            </div>
            <div className={styles.colors}>
                {counters}
            </div>
            <div className={styles.points}>
                {pointCards}
            </div>
        </div>
    )
}

export default CardDisplay