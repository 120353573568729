import { useAppSelector } from "../redux/hooks"
import styles from "../routes/Routes.module.css"

type PlayerProps = {
    id: string
}
const Player = ({id} : PlayerProps) => {

    const player = useAppSelector(state => state.lobby.players.find(p => p.id === id))
    const game = useAppSelector(state => state.lobby.game)
    const name = player ? player.name : ""
    const isHost = game && game.hostId === player?.id ? "(host)" : ""

    return(
        <div className={styles.playerName}>
            <span className={styles.green}>{name}</span>
            <span>{isHost}</span>
        </div>
    )
}

export default Player