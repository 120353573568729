import { CardState } from "../../util/types"
import { pickCard } from "../../util/websocket"
import { useAppSelector } from "../../redux/hooks"
import { allcards } from "../../carddata/allcards"
import { CardColor } from "../../carddata/types"
import styles from "./Testcard.module.css"
import Rule from "./Rule"

interface TestcardI {
    id: number,
    state: CardState,
    onBoard: boolean,
    ofPlayer: boolean
}

const Testcard = ({ id, state, onBoard, ofPlayer }: TestcardI) => {

    const game = useAppSelector(state => state.lobby.game)
    const cardData = allcards.find(c => c.id === id)

    if (!game || !cardData) return <></>

    const playerId = useAppSelector(state => state.lobby.playerId)
    const isPlayer = game.currentPlayerId === playerId
    const clickable = (isPlayer && !onBoard && game.flipPhase && ofPlayer) || (isPlayer && onBoard && !game.flipPhase && (game.firstpick || state === CardState.COLOR))

    const onClick = () => {
        if (clickable) pickCard(game.id, id)
    }

    const cardClasses = [styles.testcard]
    if (clickable) cardClasses.push(styles.clickable)
    if (cardData.color === CardColor.GREEN) cardClasses.push(styles.green)
    else if (cardData.color === CardColor.RED) cardClasses.push(styles.red)
    else if (cardData.color === CardColor.ORANGE) cardClasses.push(styles.orange)
    else if (cardData.color === CardColor.YELLOW) cardClasses.push(styles.yellow)
    else if (cardData.color === CardColor.PINK) cardClasses.push(styles.pink)
    else if (cardData.color === CardColor.PURPLE) cardClasses.push(styles.purple)

    const rules = cardData.scoreRules.map(r => <Rule id={r} /> )

    if (state === CardState.COLOR) {
        return (
            <div onClick={onClick} className={cardClasses.join(" ")}>
            </div>
        )
    }

    return (
        <div onClick={onClick} className={cardClasses.join(" ")}>
            {rules}
        </div>
    )
}

export default Testcard