import { useParams } from "react-router"
import { useLocation } from "react-router-dom"
import styles from "./Routes.module.css"
import Header from "../components/header/Header"
import { useAppSelector } from "../redux/hooks"
import Player from "../components/Player"
import { startGame } from "../util/websocket"

const LobbyPage = () => {

    const { gameId } = useParams()
    const game = useAppSelector(state => state.lobby.games.find(g => g.id === gameId))
    const playerId = useAppSelector(state => state.lobby.playerId)
    const location = useLocation()

    if (!game) return <></>

    const players = game.players.map(p => <Player id={p.id} />)
    const onStartGame = () => {
        startGame(game.id)
    }

    const onCopyPath = () => {
        navigator.clipboard.writeText(location.pathname)
        alert(`${location.pathname} copied to clipboard`)
    }

    return (
        <div className={styles.page}>
            <Header />
            <div className={styles.playersHeader}>Players</div>
            {players}
            <div className={styles.buttons}>
                <button className={styles.button} onClick={onCopyPath}>Invite Players</button>
                <button hidden={game.hostId !== playerId} className={styles.button} onClick={onStartGame}>Start Game</button>
            </div>
        </div>
    )
}

export default LobbyPage