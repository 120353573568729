import styles from "./GamePage.module.css"
import { useAppSelector } from "../redux/hooks"
import Testcard from "../components/testcard/Testcard"
import Dummy from "../components/testcard/Dummy"
import { CardState, Game } from "../util/types"
import CardDisplay from "../components/carddisplay/CardDisplay"
import { Link, NavLink } from "react-router-dom"
import { endTurn } from "../util/websocket"
import Header from "../components/header/Header"

const TurnIndicator = () => {

    const game = useAppSelector(state => state.lobby.game)
    const playerId = useAppSelector(state => state.lobby.playerId)
    const players = useAppSelector(state => state.lobby.players)

    if (!game || !playerId) return <></>

    const playerName = players.find(p => p.id === game.currentPlayerId)?.name

    if (game.finished) {

        const sortedIds = game.players.slice().sort((a, b) => b.points - a.points).map(p => p.id)
        const winner = sortedIds.length > 0 ? players.find(p => p.id === sortedIds[0]) : undefined

        return (
            <div className={[styles.turnIndicator, styles.red].join(" ")}>{`${winner?.name} has won!`}</div>
        )
    }
    else if (game.currentPlayerId === playerId) {
        return (
            <div className={[styles.turnIndicator, styles.red].join(" ")}>{`It's your turn, ${playerName} !`}</div>
        )
    } else {
        return (
            <div className={styles.turnIndicator}>{`Waiting on ${playerName} ...`}</div>
        )
    }
}

const GamePage = () => {

    const game = useAppSelector(state => state.lobby.game)
    const playerId = useAppSelector(state => state.lobby.playerId)

    if (!game || !playerId) return <div>Test</div>

    const columns = game.columns.map(c => {
        return <div className={styles.column}>
            {c.top ? <Testcard id={c.top} state={CardState.POINTS} onBoard={true} ofPlayer={false} /> : <Dummy />}
            {c.middle ? <Testcard id={c.middle} state={CardState.COLOR} onBoard={true} ofPlayer={false} /> : <Dummy />}
            {c.bottom ? <Testcard id={c.bottom} state={CardState.COLOR} onBoard={true} ofPlayer={false} /> : <Dummy />}
        </div>
    })

    let sortedPlayers = game.players
    const idx = game.players.findIndex(p => p.id === playerId)
    if (idx > 0) {
        const firstHalf = game.players.slice(idx)
        const secondHalf = game.players.slice(0, idx)
        sortedPlayers = firstHalf.concat(secondHalf)
    }
    const cardDisplays = sortedPlayers.map(p => <CardDisplay {...p} />)

    const boardStyles = [styles.board]
    if (game.currentPlayerId === playerId && !game.flipPhase) boardStyles.push(styles.highlight)

    return (
        <div className={styles.gamepage}>
            <Header />
            <TurnIndicator />
            <div className={styles.game}>
                <div className={boardStyles.join(" ")}>
                    {columns}
                </div>
                <div className={styles.playerPanel}>
                    {cardDisplays}
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <Link to="/" className={styles.backButton} hidden={!game.finished}>Back to Lobby</Link>
            </div>
        </div>
    )
}

export default GamePage