import { ClientMessage, ClientMessageType, Game, Player, PlayerPayload, PlayersPayload, ServerMessage, ServerMessageType } from "./types"
import { store } from "../redux/store"
import { setPlayers, setPlayerId, setGames, setGameId, setGame } from "../redux/lobbySlice"

const ws = new WebSocket(process.env.NODE_ENV === "production" ? "wss://beratung-huster.de" : "ws://localhost:7552")
let timerID: NodeJS.Timeout;

const keepAlive = () => {
    if (ws.readyState === 1) {
        const message: ClientMessage = {
            type: ClientMessageType.PING,
            payload: null
        }
        ws.send(JSON.stringify(message))
    }
    timerID = setTimeout(keepAlive, 30000);
}

ws.onopen = () => {
    keepAlive();
}

ws.onclose = () => {
    if (timerID) {
        clearTimeout(timerID);
    }
}

ws.onmessage = event => {
    const message: ServerMessage = JSON.parse(event.data)
    switch (message.type) {
        case ServerMessageType.Players:
            onPlayers(message.payload)
            break
        case ServerMessageType.Player:
            onPlayer(message.payload)
            break
        case ServerMessageType.Games:
            onGames(message.payload)
            break
        case ServerMessageType.NewGameId:
            onNewGameId(message.payload)
            break
        case ServerMessageType.Game:
            onGame(message.payload)
            break
        case ServerMessageType.Alive:
            onAlive()
            break
    }
}

const onAlive = () => {
    console.log("alive")
}

const onPlayers = (players: Player[]) => {
    store.dispatch(setPlayers(players))
}

const onPlayer = (id: string) => {
    store.dispatch(setPlayerId(id))
    sessionStorage.setItem("id", id)
}

const onGames = (games: Game[]) => {
    store.dispatch(setGames(games))
}

const onNewGameId = (id: string) => {
    store.dispatch(setGameId(id))
}

const onGame = (game: Game) => {
    store.dispatch(setGame(game))
}

//ToDo: check state of socket
export const login = () => {
    if (ws.readyState === ws.OPEN) {
        const message: ClientMessage = {
            type: ClientMessageType.LOGIN,
            payload: {
                id: sessionStorage.getItem("id")
            }
        }
        ws.send(JSON.stringify(message))
    } else {
        setTimeout(() => login(), 100)
    }
}

export const rename = (name: string) => {
    const message: ClientMessage = {
        type: ClientMessageType.SETPLAYERNAME,
        payload: {
            name: name
        }
    }
    ws.send(JSON.stringify(message))
}

export const newGame = () => {
    const message: ClientMessage = {
        type: ClientMessageType.NEWGAME,
        payload: {
            hostId: store.getState().lobby.playerId
        }
    }
    ws.send(JSON.stringify(message))
}

export const joinGame = (gameId: string, playerId: string) => {
    if (ws.readyState === ws.OPEN) {
        const message: ClientMessage = {
            type: ClientMessageType.JOINGAME,
            payload: {
                gameId: gameId,
                playerId: playerId
            }
        }
        ws.send(JSON.stringify(message))
    } else {
        setTimeout(() => joinGame(gameId, playerId), 100)
    }
}

export const leaveGame = (gameId: string) => {
    const message: ClientMessage = {
        type: ClientMessageType.LEAVEGAME,
        payload: {
            gameId: gameId,
            playerId: store.getState().lobby.playerId
        }
    }
    ws.send(JSON.stringify(message))
}

export const startGame = (gameId: string) => {
    const message: ClientMessage = {
        type: ClientMessageType.STARTGAME,
        payload: {
            gameId: gameId
        }
    }
    ws.send(JSON.stringify(message))
}

export const pickCard = (gameId: string, cardId: number) => {
    const message: ClientMessage = {
        type: ClientMessageType.PICKCARD,
        payload: {
            gameId: gameId,
            cardId: cardId
        }
    }
    ws.send(JSON.stringify(message))
}

export const getGame = (gameId: string) => {
    if (ws.readyState === ws.OPEN) {
        const message: ClientMessage = {
            type: ClientMessageType.GETGAME,
            payload: {
                gameId: gameId
            }
        }
        ws.send(JSON.stringify(message))
    } else {
        setTimeout(() => getGame(gameId), 100)
    }
}

export const endTurn = (gameId: string) => {
    const message: ClientMessage = {
        type: ClientMessageType.ENDTURN,
        payload: {
            gameId: gameId
        }
    }
    ws.send(JSON.stringify(message))
}