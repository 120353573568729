import { CardColor, ScoreId, ScoreRule, ScoreType } from "./types"


export const basicRules: ScoreRule[] = [
    { id: ScoreId.PINK_1, type: ScoreType.BASIC, color: CardColor.PINK, points: 1, amount: 1 },
    { id: ScoreId.PINK_2, type: ScoreType.BASIC, color: CardColor.PINK, points: 2, amount: 1 },
    { id: ScoreId.PINK_3, type: ScoreType.BASIC, color: CardColor.PINK, points: 3, amount: 1 },
    { id: ScoreId.PINK_4, type: ScoreType.BASIC, color: CardColor.PINK, points: 4, amount: 1 },
    { id: ScoreId.PINK_m1, type: ScoreType.BASIC, color: CardColor.PINK, points: -1, amount: 1 },
    { id: ScoreId.PINK_m2, type: ScoreType.BASIC, color: CardColor.PINK, points: -2, amount: 1 },
    { id: ScoreId.PINK_m4, type: ScoreType.BASIC, color: CardColor.PINK, points: -4, amount: 1 },
    { id: ScoreId.PINK_5, type: ScoreType.BASIC, color: CardColor.PINK, points: 5, amount: 2 },
    { id: ScoreId.PINK_8, type: ScoreType.BASIC, color: CardColor.PINK, points: 8, amount: 3 },
    { id: ScoreId.RED_1, type: ScoreType.BASIC, color: CardColor.RED, points: 1, amount: 1 },
    { id: ScoreId.RED_2, type: ScoreType.BASIC, color: CardColor.RED, points: 2, amount: 1 },
    { id: ScoreId.RED_3, type: ScoreType.BASIC, color: CardColor.RED, points: 3, amount: 1 },
    { id: ScoreId.RED_4, type: ScoreType.BASIC, color: CardColor.RED, points: 4, amount: 1 },
    { id: ScoreId.RED_m1, type: ScoreType.BASIC, color: CardColor.RED, points: -1, amount: 1 },
    { id: ScoreId.RED_m2, type: ScoreType.BASIC, color: CardColor.RED, points: -2, amount: 1 },
    { id: ScoreId.RED_m4, type: ScoreType.BASIC, color: CardColor.RED, points: -4, amount: 1 },
    { id: ScoreId.RED_5, type: ScoreType.BASIC, color: CardColor.RED, points: 5, amount: 2 },
    { id: ScoreId.RED_8, type: ScoreType.BASIC, color: CardColor.RED, points: 8, amount: 3 },
    { id: ScoreId.YELLOW_1, type: ScoreType.BASIC, color: CardColor.YELLOW, points: 1, amount: 1 },
    { id: ScoreId.YELLOW_2, type: ScoreType.BASIC, color: CardColor.YELLOW, points: 2, amount: 1 },
    { id: ScoreId.YELLOW_3, type: ScoreType.BASIC, color: CardColor.YELLOW, points: 3, amount: 1 },
    { id: ScoreId.YELLOW_4, type: ScoreType.BASIC, color: CardColor.YELLOW, points: 4, amount: 1 },
    { id: ScoreId.YELLOW_m1, type: ScoreType.BASIC, color: CardColor.YELLOW, points: -1, amount: 1 },
    { id: ScoreId.YELLOW_m2, type: ScoreType.BASIC, color: CardColor.YELLOW, points: -2, amount: 1 },
    { id: ScoreId.YELLOW_m4, type: ScoreType.BASIC, color: CardColor.YELLOW, points: -4, amount: 1 },
    { id: ScoreId.YELLOW_5, type: ScoreType.BASIC, color: CardColor.YELLOW, points: 5, amount: 2 },
    { id: ScoreId.YELLOW_8, type: ScoreType.BASIC, color: CardColor.YELLOW, points: 8, amount: 3 },
    { id: ScoreId.GREEN_1, type: ScoreType.BASIC, color: CardColor.GREEN, points: 1, amount: 1 },
    { id: ScoreId.GREEN_2, type: ScoreType.BASIC, color: CardColor.GREEN, points: 2, amount: 1 },
    { id: ScoreId.GREEN_3, type: ScoreType.BASIC, color: CardColor.GREEN, points: 3, amount: 1 },
    { id: ScoreId.GREEN_4, type: ScoreType.BASIC, color: CardColor.GREEN, points: 4, amount: 1 },
    { id: ScoreId.GREEN_m1, type: ScoreType.BASIC, color: CardColor.GREEN, points: -1, amount: 1 },
    { id: ScoreId.GREEN_m2, type: ScoreType.BASIC, color: CardColor.GREEN, points: -2, amount: 1 },
    { id: ScoreId.GREEN_m4, type: ScoreType.BASIC, color: CardColor.GREEN, points: -4, amount: 1 },
    { id: ScoreId.GREEN_5, type: ScoreType.BASIC, color: CardColor.GREEN, points: 5, amount: 2 },
    { id: ScoreId.GREEN_8, type: ScoreType.BASIC, color: CardColor.GREEN, points: 8, amount: 3 },
    { id: ScoreId.ORANGE_1, type: ScoreType.BASIC, color: CardColor.ORANGE, points: 1, amount: 1 },
    { id: ScoreId.ORANGE_2, type: ScoreType.BASIC, color: CardColor.ORANGE, points: 2, amount: 1 },
    { id: ScoreId.ORANGE_3, type: ScoreType.BASIC, color: CardColor.ORANGE, points: 3, amount: 1 },
    { id: ScoreId.ORANGE_4, type: ScoreType.BASIC, color: CardColor.ORANGE, points: 4, amount: 1 },
    { id: ScoreId.ORANGE_m1, type: ScoreType.BASIC, color: CardColor.ORANGE, points: -1, amount: 1 },
    { id: ScoreId.ORANGE_m2, type: ScoreType.BASIC, color: CardColor.ORANGE, points: -2, amount: 1 },
    { id: ScoreId.ORANGE_m4, type: ScoreType.BASIC, color: CardColor.ORANGE, points: -4, amount: 1 },
    { id: ScoreId.ORANGE_5, type: ScoreType.BASIC, color: CardColor.ORANGE, points: 5, amount: 2 },
    { id: ScoreId.ORANGE_8, type: ScoreType.BASIC, color: CardColor.ORANGE, points: 8, amount: 3 },
    { id: ScoreId.PURPLE_1, type: ScoreType.BASIC, color: CardColor.PURPLE, points: 1, amount: 1 },
    { id: ScoreId.PURPLE_2, type: ScoreType.BASIC, color: CardColor.PURPLE, points: 2, amount: 1 },
    { id: ScoreId.PURPLE_3, type: ScoreType.BASIC, color: CardColor.PURPLE, points: 3, amount: 1 },
    { id: ScoreId.PURPLE_4, type: ScoreType.BASIC, color: CardColor.PURPLE, points: 4, amount: 1 },
    { id: ScoreId.PURPLE_m1, type: ScoreType.BASIC, color: CardColor.PURPLE, points: -1, amount: 1 },
    { id: ScoreId.PURPLE_m2, type: ScoreType.BASIC, color: CardColor.PURPLE, points: -2, amount: 1 },
    { id: ScoreId.PURPLE_m4, type: ScoreType.BASIC, color: CardColor.PURPLE, points: -4, amount: 1 },
    { id: ScoreId.PURPLE_5, type: ScoreType.BASIC, color: CardColor.PURPLE, points: 5, amount: 2 },
    { id: ScoreId.PURPLE_8, type: ScoreType.BASIC, color: CardColor.PURPLE, points: 8, amount: 3 }
]

export const combiRules: ScoreRule[] = [
    { id: ScoreId.ORANGE_GREEN, type: ScoreType.COMBI, points: 5, colors: [CardColor.ORANGE, CardColor.GREEN] },
    { id: ScoreId.PURPLE_YELLOW, type: ScoreType.COMBI, points: 5, colors: [CardColor.PURPLE, CardColor.YELLOW] },
    { id: ScoreId.ORANGE_RED, type: ScoreType.COMBI, points: 5, colors: [CardColor.ORANGE, CardColor.RED] },
    { id: ScoreId.PURPLE_PINK, type: ScoreType.COMBI, points: 5, colors: [CardColor.PURPLE, CardColor.PINK] },
    { id: ScoreId.PURPLE_RED, type: ScoreType.COMBI, points: 5, colors: [CardColor.PURPLE, CardColor.RED] },
    { id: ScoreId.ORANGE_PINK, type: ScoreType.COMBI, points: 5, colors: [CardColor.ORANGE, CardColor.PINK] },
    { id: ScoreId.RED_GREEN, type: ScoreType.COMBI, points: 5, colors: [CardColor.RED, CardColor.GREEN] },
    { id: ScoreId.PINK_YELLOW, type: ScoreType.COMBI, points: 5, colors: [CardColor.PINK, CardColor.YELLOW] },
    { id: ScoreId.RED_YELLOW, type: ScoreType.COMBI, points: 5, colors: [CardColor.RED, CardColor.YELLOW] },
    { id: ScoreId.GREEN_PINK, type: ScoreType.COMBI, points: 5, colors: [CardColor.GREEN, CardColor.PINK] },
    { id: ScoreId.PURPLE_GREEN, type: ScoreType.COMBI, points: 5, colors: [CardColor.PURPLE, CardColor.GREEN] },
    { id: ScoreId.ORANGE_YELLOW, type: ScoreType.COMBI, points: 5, colors: [CardColor.ORANGE, CardColor.YELLOW] },
    { id: ScoreId.RED_PINK_GREEN, type: ScoreType.COMBI, points: 8, colors: [CardColor.RED, CardColor.PINK, CardColor.GREEN] },
    { id: ScoreId.ORANGE_PINK_YELLOW, type: ScoreType.COMBI, points: 8, colors: [CardColor.ORANGE, CardColor.PINK, CardColor.YELLOW] },
    { id: ScoreId.PURPLE_PINK_YELLOW, type: ScoreType.COMBI, points: 8, colors: [CardColor.PURPLE, CardColor.PINK, CardColor.YELLOW] },
    { id: ScoreId.GREEN_YELLOW_ORANGE, type: ScoreType.COMBI, points: 8, colors: [CardColor.GREEN, CardColor.YELLOW, CardColor.ORANGE] },
    { id: ScoreId.GREEN_YELLOW_PURPLE, type: ScoreType.COMBI, points: 8, colors: [CardColor.GREEN, CardColor.YELLOW, CardColor.PURPLE] },
    { id: ScoreId.GREEN_RED_ORANGE, type: ScoreType.COMBI, points: 8, colors: [CardColor.GREEN, CardColor.RED, CardColor.ORANGE] },
    { id: ScoreId.ORANGE_PURPLE_PINK, type: ScoreType.COMBI, points: 8, colors: [CardColor.ORANGE, CardColor.PURPLE, CardColor.PINK] },
    { id: ScoreId.YELLOW_PURPLE_RED, type: ScoreType.COMBI, points: 8, colors: [CardColor.YELLOW, CardColor.PURPLE, CardColor.RED] },
    { id: ScoreId.GREEN_ORANGE_PINK, type: ScoreType.COMBI, points: 8, colors: [CardColor.GREEN, CardColor.ORANGE, CardColor.PINK] },
    { id: ScoreId.PURPLE_ORANGE_RED, type: ScoreType.COMBI, points: 8, colors: [CardColor.PURPLE, CardColor.ORANGE, CardColor.RED] },
    { id: ScoreId.PINK_YELLOW_RED, type: ScoreType.COMBI, points: 8, colors: [CardColor.PINK, CardColor.YELLOW, CardColor.RED] },
    { id: ScoreId.PURPLE_GREEN_RED, type: ScoreType.COMBI, points: 8, colors: [CardColor.PURPLE, CardColor.GREEN, CardColor.RED] },
    { id: ScoreId.ALL_COLORS, type: ScoreType.COMBI, points: 12, colors: [CardColor.PURPLE, CardColor.GREEN, CardColor.RED, CardColor.ORANGE, CardColor.YELLOW, CardColor.PINK] },
]

export const minMaxEvenOddRules: ScoreRule[] = [
    { id: ScoreId.GREEN_MAX, type: ScoreType.MAX, color: CardColor.GREEN, points: 10 },
    { id: ScoreId.GREEN_MIN, type: ScoreType.MIN, color: CardColor.GREEN, points: 7 },
    { id: ScoreId.GREEN_EVEN, type: ScoreType.EVEN, color: CardColor.GREEN, points: 7 },
    { id: ScoreId.GREEN_ODD, type: ScoreType.ODD, color: CardColor.GREEN, points: 3 },
    { id: ScoreId.PURPLE_MAX, type: ScoreType.MAX, color: CardColor.PURPLE, points: 10 },
    { id: ScoreId.PURPLE_MIN, type: ScoreType.MIN, color: CardColor.PURPLE, points: 7 },
    { id: ScoreId.PURPLE_EVEN, type: ScoreType.EVEN, color: CardColor.PURPLE, points: 7 },
    { id: ScoreId.PURPLE_ODD, type: ScoreType.ODD, color: CardColor.PURPLE, points: 3 },
    { id: ScoreId.RED_MAX, type: ScoreType.MAX, color: CardColor.RED, points: 10 },
    { id: ScoreId.RED_MIN, type: ScoreType.MIN, color: CardColor.RED, points: 7 },
    { id: ScoreId.RED_EVEN, type: ScoreType.EVEN, color: CardColor.RED, points: 7 },
    { id: ScoreId.RED_ODD, type: ScoreType.ODD, color: CardColor.RED, points: 3 },
    { id: ScoreId.YELLOW_MAX, type: ScoreType.MAX, color: CardColor.YELLOW, points: 10 },
    { id: ScoreId.YELLOW_MIN, type: ScoreType.MIN, color: CardColor.YELLOW, points: 7 },
    { id: ScoreId.YELLOW_EVEN, type: ScoreType.EVEN, color: CardColor.YELLOW, points: 7 },
    { id: ScoreId.YELLOW_ODD, type: ScoreType.ODD, color: CardColor.YELLOW, points: 3 },
    { id: ScoreId.ORANGE_MAX, type: ScoreType.MAX, color: CardColor.ORANGE, points: 10 },
    { id: ScoreId.ORANGE_MIN, type: ScoreType.MIN, color: CardColor.ORANGE, points: 7 },
    { id: ScoreId.ORANGE_EVEN, type: ScoreType.EVEN, color: CardColor.ORANGE, points: 7 },
    { id: ScoreId.ORANGE_ODD, type: ScoreType.ODD, color: CardColor.ORANGE, points: 3 },
    { id: ScoreId.PINK_MAX, type: ScoreType.MAX, color: CardColor.PINK, points: 10 },
    { id: ScoreId.PINK_MIN, type: ScoreType.MIN, color: CardColor.PINK, points: 7 },
    { id: ScoreId.PINK_EVEN, type: ScoreType.EVEN, color: CardColor.PINK, points: 7 },
    { id: ScoreId.PINK_ODD, type: ScoreType.ODD, color: CardColor.PINK, points: 3 },
    { id: ScoreId.ALL_MAX, type: ScoreType.MAX, points: 10 },
    { id: ScoreId.ALL_MIN, type: ScoreType.MIN, points: 7 }
]

export const specialRules: ScoreRule[] = [
    { id: ScoreId.ALL_3, type: ScoreType.MULTIPLE, amount: 3, points: 5 },
    { id: ScoreId.ALL_2, type: ScoreType.MULTIPLE, amount: 2, points: 3 },
    { id: ScoreId.ALL_0, type: ScoreType.MULTIPLE, amount: 0, points: 5 },
]

export const allRules: ScoreRule[] = basicRules.concat(combiRules, minMaxEvenOddRules, specialRules)