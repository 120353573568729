import styles from "./Header.module.css"
import { useEffect, useState } from "react"
import { useAppSelector } from "../../redux/hooks"
import { newGame, rename } from "../../util/websocket"
import { useForm } from "react-hook-form"

interface FormData {
    name: string
}

const PlayerName = () => {

    const [editMode, setEditMode] = useState(false)
    const player = useAppSelector(state => state.lobby.players.find(p => p.id === state.lobby.playerId))
    const name = player ? player.name : ""

    const onClick = () => {
        setEditMode(true)
    }

    const { register, handleSubmit, setValue } = useForm<FormData>({
        defaultValues: {
            name: name
        }
    })

    useEffect(() => {
        setValue("name", name)
    }, [name])

    const onSubmit = (data: FormData) => {
        rename(data.name)
        setEditMode(false)
    }

    if (editMode) {
        return ((
            <div className={[styles.floatRight, styles.flexRow].join(" ")}>
                <div className={styles.label}>Name:</div>
                <form className={[styles.headerItem, styles.green].join(" ")} onSubmit={handleSubmit(onSubmit)}>
                    <input type="text" {...register("name")} />
                    <button className={styles.submitButton} type="submit">Save</button>
                </form>
            </div>
        ))
    } else {
        return ((
            <div className={[styles.floatRight, styles.flexRow].join(" ")}>
                <div className={styles.label}>Name:</div>
                <div className={[styles.headerItem, styles.green, styles.clickable].join(" ")} onClick={onClick}>{name}</div>
            </div>
        ))
    }
}


const Header = () => {

    const inGame = useAppSelector(state => {
        if (state.lobby.game) return true
        else return false
    })

    const onNewGame = () => {
        newGame()
    }

    return (
        <div className={styles.header}>
            <div className={styles.headerItem}>Jungsabend</div>
            <div hidden={inGame} onClick={onNewGame} className={[styles.headerItem, styles.green, styles.clickable].join(" ")}>New Game</div>
            <PlayerName />
        </div>
    )
}

export default Header