import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Game, Player } from "../util/types"

export interface LobbyState {
    isLoggedIn: boolean
    playerId: string
    players: Player[]
    games: Game[]
    gameId: string
    game: Game | undefined
}

const initialState: LobbyState = {
    isLoggedIn: false,
    playerId: "",
    players: [],
    games: [],
    gameId: "",
    game: undefined
}

export const lobbySlice = createSlice({
    name: "lobby",
    initialState,
    reducers: {
        login: (state) => {
            state.isLoggedIn = true
        },
        setPlayers: (state, action: PayloadAction<Player[]>) => {
            state.players = action.payload
        },
        setPlayerId: (state, action: PayloadAction<string>) => {
            state.playerId = action.payload
        },
        setGames: (state, action: PayloadAction<Game[]>) => {
            state.games = action.payload
        },
        setGameId: (state, action: PayloadAction<string>) => {
            state.gameId = action.payload
        },
        setGame: (state, action: PayloadAction<Game>) => {
            state.game = action.payload
        }
    }
})

export const { setPlayers, setPlayerId, setGames, setGameId, setGame } = lobbySlice.actions

export default lobbySlice.reducer