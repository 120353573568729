import { useAppSelector } from "../../redux/hooks"
import { joinGame } from "../../util/websocket"
import styles from "./Games.module.css"

const Games = () => {

    const playerId = useAppSelector(state => state.lobby.playerId)
    const players = useAppSelector(state => state.lobby.players)

    const onJoinGame = (gameId: string) => {
        joinGame(gameId, playerId)
    }

    const games = useAppSelector(state => state.lobby.games).map(g => {
        const playername = players.find(p => p.id === g.hostId)?.name
        return (
            <div className={styles.row} key={g.id} onClick={() => onJoinGame(g.id)}>
                <span>game</span>
                <span className={styles.green}>{g.id}</span>
                <span>hosted by</span>
                <span className={styles.green}>{playername}</span>
            </div>
        )
    })
    if (games.length === 0) games.push(<div>no open games</div>)

    return (
        <div className={styles.games}>
            <div className={styles.title}>Open Games</div>
            {games}
        </div>
    )
}

export default Games