//ToDo: extract types to shared resource
export type Player = {
    id: string
    name: string
}

export enum ClientMessageType {
    LOGIN,
    SETPLAYERNAME,
    NEWGAME,
    JOINGAME,
    LEAVEGAME,
    STARTGAME,
    GETGAME,
    PICKCARD,
    ENDTURN,
    PING
}

export enum ServerMessageType {
    Player,
    Players,
    Games,
    NewGameId,
    Game,
    Alive
}

export type ClientMessage = {
    type: ClientMessageType
    payload: any
}

export type ServerMessage = {
    type: ServerMessageType
    payload: any
}

export type SetPlayerNamePayload = {
    id: string
    name: string
}

export type PlayersPayload = {
    players: Player[]
}

export type PlayerPayload = {
    id: string
}

export enum CardState {
    POINTS,
    COLOR
}

export type Card = {
    id: number
    state: CardState
}

export type Column = {
    top: number | undefined
    middle: number | undefined
    bottom: number | undefined
}

export type GamePlayer = {
    id: string
    cards: Card[]
    points: number
}

export type Game = {
    id: string
    hostId: string
    players: GamePlayer[]
    started: boolean
    currentPlayerId: string
    deck: number[]
    columns: Column[]
    firstpick: boolean
    finished: boolean
    flipPhase: boolean
}