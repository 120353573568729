import { useEffect } from "react"
import { login } from "../util/websocket"
import { RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom"
import MainPage from "./MainPage"
import GameRedirect from "./GameRedirect"

const Routes = () => {

    useEffect(() => {
        login()
    }, [])

    const routes: RouteObject[] = [
        {
            path: "/",
            element: <MainPage />
        }, {
            path: "/:gameId",
            element: <GameRedirect />
        },
    ]

    const router = createBrowserRouter(routes)

    return (
        <RouterProvider router={router} />
    )
}

export default Routes