import Header from "../components/header/Header"
import styles from "./Routes.module.css"
import PlayerBar from "../components/playerbar/PlayerBar"
import Games from "../components/games/Games"
import CustomRouter from "../util/CustomRouter"

const MainPage = () => {

    return (
        <div className={styles.page}>
            <Header />
            <Games />
            <CustomRouter />
        </div>
    )
}

export default MainPage