export enum ScoreType {
    BASIC = 1,
    COMBI,
    MAX,
    MIN,
    EVEN,
    ODD,
    MULTIPLE
}

export type ScoreRule = {
    id: number
    type: ScoreType
    color?: CardColor
    points: number
    amount?: number
    colors?: CardColor[]
}

export enum ScoreId {
    PINK_1 = 1,
    PINK_2,
    PINK_3,
    PINK_4,
    PINK_5,
    PINK_8,
    PINK_m1,
    PINK_m2,
    PINK_m4,
    RED_1,
    RED_2,
    RED_3,
    RED_4,
    RED_5,
    RED_8,
    RED_m1,
    RED_m2,
    RED_m4,
    GREEN_1,
    GREEN_2,
    GREEN_3,
    GREEN_4,
    GREEN_5,
    GREEN_8,
    GREEN_m1,
    GREEN_m2,
    GREEN_m4,
    YELLOW_1,
    YELLOW_2,
    YELLOW_3,
    YELLOW_4,
    YELLOW_5,
    YELLOW_8,
    YELLOW_m1,
    YELLOW_m2,
    YELLOW_m4,
    PURPLE_1,
    PURPLE_2,
    PURPLE_3,
    PURPLE_4,
    PURPLE_5,
    PURPLE_8,
    PURPLE_m1,
    PURPLE_m2,
    PURPLE_m4,
    ORANGE_1,
    ORANGE_2,
    ORANGE_3,
    ORANGE_4,
    ORANGE_5,
    ORANGE_8,
    ORANGE_m1,
    ORANGE_m2,
    ORANGE_m4,
    ORANGE_GREEN,
    PURPLE_YELLOW,
    ORANGE_RED,
    PURPLE_PINK,
    PURPLE_RED,
    ORANGE_PINK,
    RED_GREEN,
    PINK_YELLOW,
    RED_YELLOW,
    GREEN_PINK,
    PURPLE_GREEN,
    ORANGE_YELLOW,
    RED_PINK_GREEN,
    ORANGE_PINK_YELLOW,
    PURPLE_PINK_YELLOW,
    GREEN_YELLOW_ORANGE,
    GREEN_YELLOW_PURPLE,
    GREEN_RED_ORANGE,
    ORANGE_PURPLE_PINK,
    YELLOW_PURPLE_RED,
    GREEN_ORANGE_PINK,
    PURPLE_ORANGE_RED,
    PINK_YELLOW_RED,
    PURPLE_GREEN_RED,
    ALL_COLORS,
    GREEN_MIN,
    GREEN_MAX,
    GREEN_EVEN,
    GREEN_ODD,
    RED_MIN,
    RED_MAX,
    RED_EVEN,
    RED_ODD,
    YELLOW_MIN,
    YELLOW_MAX,
    YELLOW_EVEN,
    YELLOW_ODD,
    PINK_MIN,
    PINK_MAX,
    PINK_EVEN,
    PINK_ODD,
    PURPLE_MIN,
    PURPLE_MAX,
    PURPLE_EVEN,
    PURPLE_ODD,
    ORANGE_MIN,
    ORANGE_MAX,
    ORANGE_EVEN,
    ORANGE_ODD,
    ALL_MIN,
    ALL_MAX,
    ALL_2,
    ALL_3,
    ALL_0
}

export enum CardColor {
    RED = 1,
    GREEN,
    YELLOW,
    PURPLE,
    PINK,
    ORANGE
}

export type CardData = {
    id: number
    color: CardColor
    scoreRules: number[]
}